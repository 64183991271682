* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.container {
  max-width: 133rem !important;
  margin: 0 auto !important;
  padding: 0 2.5rem !important;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #0c5dc7 #ffffff;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:hover {
  background-color: #ffffff;
}

*::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #030341;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #1a1a5b;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #1a1a5b;
}

.navbar {
  max-width: 133rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.7rem 2rem;
  position: center;
  top: 0;
  left: 0;
  right: 4rem;
  background-color: rgba(255, 255, 255, 0.724);
  z-index: 99999;
  margin: 0 auto;
}
.navbar__img {
  width: 14.5rem;
}
.navbar__img img {
  width: 100%;
  height: 100%;
}
.navbar__links {
  display: flex;
  list-style: none;
  gap: 2.1rem;
}
.navbar__links a {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #424245;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s;
}
.navbar__links a:hover {
  color: #0c5dc7;
}
@media (max-width: 1000px) {
  .navbar__links {
    display: none;
  }
}
.navbar__buttons {
  display: flex;
  gap: 2.5rem;
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  align-items: center;
}
@media (max-width: 1000px) {
  .navbar__buttons {
    display: none;
  }
}
.navbar__buttons__sign-in {
  color: #1c1c1c;
  cursor: pointer;
  transition: all 0.3s;
}
.navbar__buttons__sign-in:hover {
  color: #0c5dc7;
}
.navbar__buttons__register {
  background-color: #0c5dc7;
  color: white;
  padding: 1.5rem 3rem;
  border-radius: 3px;
  box-shadow: 0 10px 15px 0 rgba(48, 114, 255, 0.35);
  transition: all 0.3s;
}
.navbar__buttons__register:hover {
  box-shadow: 0 10px 15px 0 rgba(255, 83, 48, 0.5);
  background-color: #fa4226;
}
.navbar__buttons a {
  text-decoration: none;
}

.mobile-hamb {
  font-size: 2.8rem;
  display: none;
  cursor: pointer;
  transition: all 0.3s;
}
.mobile-hamb:hover {
  color: #0c5dc7;
}
@media (max-width: 1000px) {
  .mobile-hamb {
    display: flex;
  }
}

.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: #ffffff;
  z-index: 999999;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
}
.mobile-navbar__close {
  font-size: 3rem;
  position: absolute;
  top: 3.5rem;
  right: 3.5rem;
  cursor: pointer;
  transition: all 0.3s;
}
.mobile-navbar__close:hover {
  color: #0c5dc7;
}
.mobile-navbar__links {
  display: flex;
  flex-direction: column;
  list-style: none;
  font-size: 2.3rem;
  gap: 3rem;
  text-align: center;
}


.mobile-navbar__links li a {
  text-decoration: none;
  color: #010103;
  font-weight: 500;
  transition: all 0.3s;
}
.mobile-navbar__links li a:hover {
  color: #0c5dc7;
}

.mobile-navbar__links ul{
  font-size: 1.8rem;
}
.open-nav {
  left: 0;
}

.hero-section {
  width: 100%;
  height: 97vh;
  background-color: #f8f8f8;
  position: relative;
}

.bg-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 95rem;
  height: 58.3rem;
  
 
}
@media (max-width: 960px) {
  .bg-shape {
    display: none;
  }
}

.hero-content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}
@media (max-width: 800px) {
  .hero-content {
    justify-content: center;
  }
}
.hero-content__text {
  display: flex;
  flex-direction: column;
  z-index: 3;
  max-width: 50rem;
  margin-top: 5rem;
  
}
@media (max-width: 800px) {
  .hero-content__text {
    text-align: center;
    align-items: center;
    
    
  }
}

.hero-content__text img{
  width: 80%;
}

.hero-content__text h4 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  color: #010103;
  /* text-shadow: 10px 10px 20px rgba(0, 0, 0, 0.8) ; */
  
}
.hero-content__text h1 {
  font-size: 5.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #020228;
  line-height: 1.2;
  margin-top: 1rem;
  margin-bottom: 2.3rem;
  /* text-shadow: 10px 10px 30px rgba(0, 0, 0, 0.8) ; */
}
.hero-content__text h1 span {
  color:  #d22b25;
  /* text-shadow: 10px 10px 30px rgba(0, 0, 0, 0.8) ; */
}
.hero-content__text p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.6;
  color: #2f2f31;
  width: 60%;
  margin-bottom: 4rem;
  /* text-shadow: 10px 10px 30px rgba(0, 0, 0, 0.8) ; */
}
.hero-content__text__btns {
  display: flex;
  gap: 2rem;
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
}
@media (max-width: 450px) {
  .hero-content__text__btns {
    flex-direction: column;
  }
}
.hero-content__text__btns a {
  text-decoration: none;
  color: white;
  font-weight: 700;
}
.hero-content__text__btns__book-ride {
  background-color: #d22b25;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(255, 72, 48, 0.35);
  transition: all 0.3s;
  border: 2px solid #d22b25;
}
@media (max-width: 450px) {
  .hero-content__text__btns__book-ride {
    padding: 1.8rem 8rem;
  }
}
.hero-content__text__btns__book-ride:hover {
  box-shadow: 0 10px 15px 0 rgba(220, 46, 11, 0.35);
  background-color: #f82f10;
}
.hero-content__text__btns__learn-more {
  background-color: #030341;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  border: 2px solid #03033b;
  transition: all 0.3s;
}
.hero-content__text__btns__learn-more:hover {
  background-color: transparent;
  color: #010103;
}
.hero-content__car-img {
  z-index: 2;
  position: absolute;
  right: 0;
  width: 65%;
  margin-top: 5rem;
}
@media (max-width: 800px) {
  .hero-content__car-img {
    display: none;
  }
}

.scroll-up {
  position: fixed;
  font-size: 2.5rem;
  color: white;
  background-color: #1a1a5b;
  border: 3px solid white;
  width: 2rem;
  height: 2rem;
  bottom: 5rem;
  right: 5rem;
  z-index: 20;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
}

@media (max-width:800px){
  .scroll-up{
    right: 4cm;
  }
}
.fa-solid{
  font-style: normal;
}

.show-scroll {
  display: flex;
}

.book-section {
  position: relative;
  background: linear-gradient(to bottom, #f8f8f8 20%, #ffffff 80%);
}

.book-content {
  margin: 0 auto;
  margin-bottom: 10rem;
}
.book-content__box {
  background-color: #ffffff;
  width: 100%;
  padding: 4rem 4.5rem 5rem 5.5rem;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  height: auto;
  position: relative;
  z-index: 4;
  color: white;
  border-radius: 5px;
  /* background-image: url("/src/images/book-car/book-bg.png"); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: #010103;
}
@media (max-width: 400px) {
  .book-content__box {
    padding: 4rem 2.5rem 5rem 2.5rem;
  }
}
.book-content__box h2 {
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 2.7rem;
}
.book-content__box .box-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 2rem;
}
.book-content__box .box-form b {
  color: #ff4d30;
}
@media (max-width: 1000px) {
  .book-content__box .box-form {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 630px) {
  .book-content__box .box-form {
    grid-template-columns: 1fr;
  }
}
.book-content__box .box-form__car-type {
  display: flex;
  flex-direction: column;
}
.book-content__box .box-form__car-type label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
}
.book-content__box .box-form__car-type label i {
  color: #ff4d30;
}
.book-content__box .box-form__car-type select {
  font-size: 1.5rem;
  color: #ababab;
  font-family: "Rubik", sans-serif;
  border: 1px solid #ccd7e6;
  border-radius: 3px;
  font-weight: 400;
  padding: 1.2rem 1.3rem;
  outline: none;
}
.book-content__box .box-form__car-time {
  display: flex;
  flex-direction: column;
  position: relative;
}
.book-content__box .box-form__car-time input {
  outline: none;
  color: #878585;
  padding-right: 3rem;
  border: 1px solid #ccd7e6;
}
.book-content__box .box-form__car-time label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
}
.book-content__box .box-form__car-time label i {
  color: #ff4d30;
}
.book-content__box .box-form__car-time ::-webkit-datetime-edit {
  padding: 1.3rem 1.3rem;
}
.book-content__box .box-form__car-time ::-webkit-calendar-picker-indicator {
  cursor: pointer;
  font-size: 1.6rem;
}
.book-content__box button {
  padding: 1.3rem 1.3rem;
  height: -moz-fit-content;
  height: fit-content;
  align-self: self-end;
  border: none;
  font-family: "Rubik", sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
  background-color: #ff4d30;
  box-shadow: 0 10px 15px rgba(255, 83, 48, 0.35);
  cursor: pointer;
  transition: all 0.3s;
}
.book-content__box button:hover {
  box-shadow: 0 10px 15px rgba(255, 83, 48, 0.55);
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 1rem 1.4rem;
  margin-bottom: 1.8rem;
  margin-top: -1rem;
  border: 1px solid transparent;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: none;
  justify-content: space-between;
  align-items: center;
}
.error-message i {
  cursor: pointer;
}

.booking-done {
  color: #2a6817;
  background-color: #c3fabe;
  border-color: #f5c6cb;
  padding: 1rem 1.4rem;
  margin-bottom: 1.8rem;
  margin-top: -1rem;
  border: 1px solid transparent;
  border-radius: 0.8rem;
  font-size: 1.6rem;
  font-weight: 500;
  display: none;
  justify-content: space-between;
  align-items: center;
}
.booking-done i {
  cursor: pointer;
}

.active-modal {
  opacity: 1 !important;
  display: flex !important;
}

.modal-overlay {
  opacity: 0;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999999999;
  top: 0;
  right: 0;
}

.booking-modal {
  opacity: 0;
  display: none;
  flex-direction: column;
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999999999999;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 83rem;
  height: 100vh;
  border: 2px solid white;
  background-color: rgb(255, 255, 255);
  padding-right: 2px;
  color: #010103;
}
@media (max-width: 800px) {
  .booking-modal {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .booking-modal {
    top: 50%;
  }
}
.booking-modal__title {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  background-color: #ff4d30;
  color: white;
  align-items: center;
}
.booking-modal__title h2 {
  font-size: 2.4rem;
  text-transform: uppercase;
}
.booking-modal__title i {
  font-size: 2.5rem;
  color: rgba(255, 255, 255, 0.919);
  cursor: pointer;
  transition: all 0.2;
}
.booking-modal__title i:hover {
  color: white;
}
.booking-modal__message {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 3rem;
  background-color: #ffeae6;
}
.booking-modal__message h4 {
  font-size: 1.9rem;
  font-weight: 700;
  color: #ff4d30;
}
.booking-modal__message h4 i {
  font-size: 2.5rem;
}
.booking-modal__message p {
  font-size: 1.7rem;
  color: #777777;
  font-weight: 500;
  line-height: 1.6;
}
.booking-modal__car-info {
  background-color: white;
  padding: 3rem 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(119, 119, 119, 0.6235294118);
}
@media (max-width: 650px) {
  .booking-modal__car-info {
    grid-template-columns: 1fr;
    text-align: center;
  }
}
.booking-modal__car-info__dates {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.booking-modal__car-info__dates h5 {
  font-size: 1.8rem;
  color: #ff4d30;
}
.booking-modal__car-info__dates span {
  display: flex;
  gap: 1rem;
}
@media (max-width: 650px) {
  .booking-modal__car-info__dates span {
    grid-template-columns: 1fr;
    text-align: center;
    justify-content: center;
  }
}
.booking-modal__car-info__dates span i {
  font-size: 1.8rem;
  padding-top: 0.2rem;
  color: #777777;
}
@media (max-width: 650px) {
  .booking-modal__car-info__dates span i {
    display: none;
  }
}
.booking-modal__car-info__dates span h6 {
  font-size: 1.5rem;
  margin-bottom: 0.2rem;
}
.booking-modal__car-info__dates span p {
  font-size: 1.6rem;
  color: #777777;
}
.booking-modal__car-info__model {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
@media (max-width: 650px) {
  .booking-modal__car-info__model {
    margin-top: 3.5rem;
  }
}
.booking-modal__car-info__model h5 {
  font-size: 1.8rem;
  color: #ff4d30;
}
.booking-modal__car-info__model h5 span {
  color: #010103;
}
.booking-modal__car-info__model img {
  width: 100%;
  height: auto;
}
.booking-modal__person-info {
  padding: 3rem 3rem;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.booking-modal__person-info h4 {
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #ff4d30;
  margin-bottom: 2rem;
}

.input-time {
  text-align: center;
  width: 8rem;
  font-size: 14px;
  cursor: pointer;
}

.info-form {
  display: flex;
  flex-direction: column;
}
.info-form__checkbox {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1.6rem;
  color: #565454;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.info-form__checkbox input {
  width: 1.6rem;
  height: 1.6rem;
}
.info-form__1col {
  grid-template-columns: 1fr !important;
}
.info-form__2col, .info-form__1col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding: 1rem 0;
}
@media (max-width: 650px) {
  .info-form__2col, .info-form__1col {
    grid-template-columns: 1fr;
  }
}
.info-form__2col span, .info-form__1col span {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.info-form__2col span label, .info-form__1col span label {
  font-size: 1.6rem;
  font-weight: 500;
  color: #777777;
}
.info-form__2col span label b, .info-form__1col span label b {
  color: #ff4d30;
}
.info-form__2col span input, .info-form__1col span input {
  padding: 14px 15px;
  background-color: #dbdbdb;
  color: #555;
  font-size: 1.5rem;
  font-weight: 500;
  outline: none;
  border: none;
}

.reserve-button {
  background-color: #dbdbdb;
  margin: 0 -3rem;
  padding: 3rem;
  text-align: right;
}
@media (max-width: 650px) {
  .reserve-button {
    text-align: center;
  }
}
.reserve-button button {
  font-size: 2.4rem;
  color: white;
  font-weight: 700;
  background-color: #ff4d30;
  border: 1px solid yellow;
  padding: 1.2rem 2rem;
  cursor: pointer;
  transition: all 0.2s;
}
.reserve-button button:hover {
  background-color: #fe3516;
}

.dates-div {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}

.plan-section {
  background-color: #f5e1e015;
  padding: 7rem 0;
  
}

.plan-container {
  display: flex;
  background-color: #f2f2f2;
  flex-direction: column;
  box-shadow: 10px 10px 10px 15px rgba(0, 0, 0, 0.08);
  border-radius: 5rem;
  height: auto;
  
}

.icon1{
  margin-bottom: 3rem;
  border-radius: 40%;
}

.icon1:hover{
  box-shadow: 0 10px 15px rgba(8, 236, 126, 0.352);
  background-color: #bdf8d6cd;
}

.icon2{
  margin-bottom: 3rem;
  border-radius: 40%;
}

.icon2:hover{
  box-shadow: 0 10px 15px rgba(8, 23, 236, 0.276);
  background-color: #bdd6f8cd;
}

.icon3{
  margin-bottom: 3rem;
  border-radius: 40%;
}

.icon3:hover{
  box-shadow: 0 10px 15px rgba(253, 93, 24, 0.276);
  background-color: #f8cdbdcd;
}

.plan-container__title {
  margin: 0 auto;
  text-align: center;
  color: #030317;
  
}
.plan-container__title h3 {
  font-size: 2.4rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.plan-container__title h2 {
  font-size: 4.5rem;
  font-family: "Poppins", sans-serif;
  margin: 1.3rem 0 3rem 0;
  font-weight: 700;
  
}
.plan-container__boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin-top: 3.7rem;
  padding: 0 3rem;
 
}

.row{
  margin-top: 3.7rem;
  display: flex;
  background-color: #ffffff;
  width: 90%;
  margin-left: 5rem;
  /* border-radius: 0%;
  box-shadow: 10px 10px 10px 20px rgba(0, 0, 0, 0.08); */
}

@media (max-width: 800px) {
  .row{
    width: 65%;
  }

}

.form-label{
  font-size: 1.5rem;
}

.form-control{
  height: 4rem;
}

.form-select{
  height: 4rem;
  font-size: 1.2rem;
}

.btn{

font-size: 2rem;
color: #ffffff;
width: 20%;
height: 4rem;

}

@media (max-width: 800px) {
  .btn{
    width: 65%;
  }

}


@media (max-width: 1021px) {
  .plan-container__boxes {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }
}
@media (max-width: 800px) {
  .plan-container__boxes {
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}
.plan-container__boxes__box {
  text-align: center;
  padding: 1rem 6rem;
}
@media (max-width: 500px) {
  .plan-container__boxes__box {
    padding: 1rem 1rem;
  }
}
.plan-container__boxes__box img {
  width: 17rem;
  height: auto;
}

.testi-link{
  text-decoration: none;
}
.plan-container__boxes__box h3 {
  font-size: 2.4rem;
  margin-bottom: 1rem;
  color: #010103;
  transition: all 0.2s;
}
.plan-container__boxes__box p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #19191a;
  line-height: 1.43;
}



.cta-btn {
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: white;
  font-family: "Poppins", sans-serif;
  background-color: #ff4d30;
  padding: 1rem 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.4rem;
  transition: all 0.3s;
  box-shadow: 6px 6px 0 #efe9e9;
}
.cta-btn:hover {
  background-color: #e9381d;
}

.box-cars {
  gap: 11rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .box-cars {
    gap: 2rem;
  }
}
@media (max-width: 700px) {
  .box-cars {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
}

.colored-button {
  background-color: #ff4d30 !important;
  color: white;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(0, 0, 0, 0.2588235294);
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 40%;
  right: 43%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.choose-section {
  background-color: white;
  padding: 2rem 0 0rem 0;
  
  background-position: -60px 300px;
  background-size: auto;
  background-repeat: no-repeat;
}

.choose-container {
  display: flex;
  flex-direction: column;
}
.choose-container__img {
  width: 90%;
  height: auto;
  margin: 0 auto;
}
@media (max-width: 550px) {
  .choose-container__img {
    width: 100%;
    background-image: 0px 0px;
    background-image: none;
  }
}

.text-container {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  width: 100%;
}
@media (max-width: 1000px) {
  .text-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
  }
}
.text-container__left {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #010103;
}
@media (max-width: 1000px) {
  .text-container__left {
    align-items: center;
    text-align: center;
  }
}
.text-container__left h4 {
  font-size: 2.2rem;
  margin-bottom: 0.7rem;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}
.text-container__left h2 {
  font-size: 4.2rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}
.text-container__left p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  color: #706f7b;
  margin-bottom: 3.3rem;
}
.text-container__left a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #07115e;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(48, 82, 255, 0.35);
  transition: all 0.3s;
  border: 2px solid #150281;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
}
.text-container__left a:hover {
  box-shadow: 0 10px 15px 0 rgba(58, 48, 255, 0.6);
  background-color: #1a1c7c;
}
.text-container__right {
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  max-width: 44rem;
}
.text-container__right__box {
  display: flex;
}
@media (max-width: 550px) {
  .text-container__right__box {
    flex-direction: column;
    align-items: center;
  }
}
.text-container__right__box img {
  width: 40rem;
  
  margin-right: 80rem;
}
@media (max-width:800px){
  .text-container__right__box img{
    width: 20rem;
    margin-right: 0rem;
  }
}
.text-container__right__box
.text-container__right__box__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}


.text-container__right__box__text h4 {
  font-size: 2.4rem;
}
.text-container__right__box__text p {
  font-size: 1.6rem;
  color: #0a0a0a;
  font-family: "Rubik", sans-serif;
  line-height: 1.3;
}

.servicios-section {
  background-color: #f8f8f8;
  padding: 10rem 0;
  color: #010103;
}

.servicios-content {
  display: flex;
  flex-direction: column;
}
.servicios-content__title {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 70rem;
  margin-bottom: 5rem;
}

.Politic_form p{
  margin-top: 1.5cm;
  margin-left: 1rem;
  text-align: justify;
  font-size: small;
  max-width: 90%;
}
.servicios-content__title h4 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.servicios-content__title h2 {
  font-size: 4.2rem;
  margin-bottom: 1.4rem;
}
.servicios-content__title p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.4;
}

.all-servicios {
  display: flex;
  gap: 3rem;
  width: 100%;
  justify-content: center;
  padding: 3rem;
}
@media (max-width: 1000px) {
  .all-servicios {
    padding: 0;
  }
}
.all-servicios__box {
  background-color: rgba(252, 152, 3, 0.425);
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.08);
  width: 50rem;
  height: 40rem;
  padding: 5.5rem;
  position: relative;
  border-radius: 2%;
}
@media (max-width: 1000px) {
  .all-servicios__box {
    padding: 10rem 3rem;
    grid-template-columns: 1fr;
    
  }
}
.all-servicios__box p {
  font-size: 1.5rem;
  font-weight: 500;
  color: #424245;
}
.all-servicios__box__name {
  display: flex;
}
.all-servicios__box__name__profile {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 1rem;
  height: 1rem;
}

.all-servicios__box__name__profile img {
  
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  margin-top: 10rem;
  margin-left: 1rem;
}

/* Tramites Migratorios */

.tramitesmig-section {
  background:linear-gradient(rgb(2, 2, 80), rgb(4, 71, 186), rgb(214, 47, 29) );
  background-position: -140px 0px;
  background-size: 2800px;
  padding: 10rem 0;
  margin-block-end: 5rem;
  color: #f5f5f7;
}

.tramitesmig-content__title {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 70rem;
  margin-bottom: 5rem;
  margin-left: 12rem;
}

@media (max-width: 960px) {
  .tramitesmig-content__title {
    font-size: 3.8rem;
    margin-right: 30rem;
  }

}
@media (max-width: 530px) {
  .tramitesmig-content__title {
    font-size: 3.8rem;
    margin-right: 1rem;
  }

}

.text-container__left-2 a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #ff0000;
  padding: 1.5rem 4rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(255, 48, 48, 0.35);
  transition: all 0.3s;
  border: 2px solid #ff0000;
  font-size: 1.6rem;
  width: -moz-fit-content;
  position: relative;
  bottom: 20rem;
  left: 40rem;
  width: fit-content;
}
.text-container__left-2 a:hover {
  box-shadow: 0 10px 15px 0 rgba(255, 48, 48, 0.6);
  background-color: #fc1b1b7c;
}

.text-container__left-3 a {
  text-decoration: none;
  color: rgb(17, 17, 17);
  font-weight: 700;
  background-color: #ffffff;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(235, 231, 231, 0.35);
  transition: all 0.3s;
  border: 2px solid #0a0a0a;
  font-size: 1.6rem;
  width: -moz-fit-content;
  position: relative;
  bottom: 15rem;
  left: 40rem;
  width: fit-content;
}
.text-container__left-3 a:hover {
  box-shadow: 0 10px 15px 0 rgba(248, 244, 244, 0.6);
  background-color: #fefcfcb3;
}

@media (max-width: 960px) {
  .text-container__left-3 a{
    top: -17rem;
  }
}
@media (max-width: 530px) {
  .text-container__left-3 a{
    top: 1rem;
  }
}

@media (max-width: 960px) {
  .tramitesmig-content__title a{
    bottom: 19rem;
    margin-right: 7rem;
  }
}
@media (max-width: 530px) {
  .tramitesmig-content__title a{
    bottom: 1rem;
    margin-left: -110rem;
  }
}
.tramitesmig-content__title h4 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
}
.tramitesmig-content__title h2 {
  font-size: 7rem;
  margin-bottom: 1.4rem;
}

@media (max-width: 960px) {
  .tramitesmig-content__title h2{
    font-size: 6rem;
    margin-right: 7rem;
  }

}

@media (max-width: 530px) {
  .tramitesmig-content__title h2{
    font-size: 3.8rem;
    width: 60%;
    margin-left: -10rem;
  }

}

.tramitesmig-content__title p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #d0cfd7;
  line-height: 1.4;
  margin-left: 14rem;
  width: 60%;
}

@media (max-width: 960px) {
  .tramitesmig-content__title p{
    font-size: 1.4rem;
    margin-left: -1rem;
    width: 90%;
  }

}

@media (max-width: 530px) {
  .tramitesmig-content__title p{
    font-size: 1.3rem;
    margin-left: -12rem;
    width: 70%;
  }

}

.tramitesmig-content {
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr;
  
}


@media (max-width: 560px) {
  .tramitesmig-content {
    grid-template-columns: 1fr;
    width: -moz-fit-content;
    width: fit-content;
    
  }
}

.all-tramitesmig {
  display: grid;
  gap: 4rem;
  padding: 3rem;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 980px){
  .all-tramitesmig{
      width: 100%;
      grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 960px) {
  .all-tramitesmig {
    grid-template-columns: 1fr 1fr;
    width: 100%;
    width: fit-content;
    position: relative;
    right: 4.3rem;
    align-items: center;
  }
}


@media (max-width: 560px) {
  .all-tramitesmig {
    grid-template-columns: 1fr;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    right: 4.3rem;
    align-items: center;
  }
}

.all-tramitesmig__box {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 20px 40px 0 rgba(32, 32, 32, 0.61);
  width: 50rem;
  height: 55rem;
  padding: 5.5rem;
  position: relative;
  border-radius: 2%;
  
}

@media (max-width: 960px) {
  .all-tramitesmig__box {
    grid-template-columns: 1fr 1fr;
    width: -moz-fit-content;
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 530px) {
  .all-tramitesmig__box {
    grid-template-columns: 1fr;
    width: -moz-fit-content;
    width: 70%;
    align-items: center;
  }
}
 
.all-tramitesmig__box a{
  text-decoration: none;
  
}
.all-tramitesmig__box h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #101010;
  width: 70%;
  text-decoration: none;
}
.all-tramitesmig__box h1:hover {
  color: #1a39d3; 
}

.all-tramitesmig__box p {
  font-size: 1.3rem;
  font-weight: 500;
  color: #424245;
  width: 100%;
  text-align: justify;
  
}

@media (max-width: 530px) {
  .all-tramitesmig__box p{
    font-size: 1.2rem;
    width: 120%;
    margin-left: -2rem;
  }
}

.all-tramitesmig__box__name {
  display: flex;
}
.all-tramitesmig__box__name__profile {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-top: 1rem;
  height: 1rem;
}

.all-tramitesmig__box__name__profile1 img {
  
  width: 18rem;
  height: 18rem;
  border-radius: 0%;
  position: relative;
  margin-top: 4rem;
  margin-left: 12rem;
  text-decoration: none;
  
}

.all-tramitesmig__box__name__profile1-2 img {
  
  width: 10rem;
  height: 10rem;
  border-radius: 0%;
  position: relative;
  bottom: 48rem;
  margin-left: 29rem;
  
}

@media (max-width: 530px) {
  .all-tramitesmig__box__name__profile1-2 img{
bottom: 51rem;  
right: 9rem;

}
}

.all-tramitesmig__box__name__profile2 img {
  
  width: 15rem;
  height: 15rem;
  border-radius: 0%;
  margin-top: 2rem;
  margin-left: 12rem;
  justify-content: space-around;
}

@media (max-width: 530px) {
  .all-tramitesmig__box__name__profile2 img{
  margin-top: -1rem;
  width: 12rem;
  height: 12rem;
  
  
  }
  }

.all-tramitesmig__box__name__profile2-2 img {
  
  width: 15rem;
  height: 15rem;
  border-radius: 0%;
  margin-top: 3rem;
  margin-left: 12rem;
  justify-content: space-around;
}


.all-tramitesmig__box__name__profile3 img {
  
  width: 15rem;
  height: 15rem;
  border-radius: 0%;
  margin-top: 2rem;
  margin-left: 12rem;
  justify-content: space-around;
}

@media (max-width: 530px) {
  .all-tramitesmig__box__name__profile3 img{
  margin-top: -2rem;
  width: 13rem;
  height: 13rem;
  }
  }

.all-tramitesmig__box__name__profile3-2 img {
  
  width: 8rem;
  height: 8rem;
  border-radius: 0%;
  margin-top: -78rem;
  margin-left: 33rem;
  
}

@media (max-width: 530px) {
.all-tramitesmig__box__name__profile3-2 img{
margin-top: -90rem;
margin-left: 19rem;

}
}

.all-tramitesmig__box__name__profile4 img {
  
  width: 18rem;
  height: 18rem;
  border-radius: 0%;
  margin-top: 3rem;
  margin-left: 12rem;
  
}

.all-tramitesmig__box__name__profile4-2 img {
  
  width: 20rem;
  height: 20rem;
  border-radius: 0%;
  margin-top: 6rem;
  margin-left: 8rem;
  
}


.quotes-icon {
  font-size: 6.2rem;
  color: #eb0a0a;
  position: absolute;
  bottom: 10px;
  right: 60px;
}
@media (max-width: 470px) {
  .quotes-icon {
    display: none;
  }
}

@media (max-width: 900px) {
  .box-2 {
    display: none;
  }
}

.faq-section {
  background-image: url("/src/images/faq/fondo.jpg");  
  padding: 10rem 0;
  background-size: 2140px;
  background-repeat: no-repeat;
  background-position: 0 70%;
  background-color: #d61313e8;
  
}


.faq-content {
  display: flex;
  flex-direction: column;
  color: #fcfcfe;
}
.faq-content__title {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 80rem;
  line-height: 1.5;
}
.faq-content__title h5 {
  font-size: 2.2rem;
}
.faq-content__title h2 {
  font-size: 4.2rem;
  margin-bottom: 1.7rem;
}
.faq-content__title p {
  font-size: 1.6rem;
  color: #e9e9f1;
}

.all-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
}

.faq-box {
  display: flex;
  flex-direction: column;
  color: #010103;
  background-color: white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  width: 80rem;
  cursor: pointer;
}
@media (max-width: 850px) {
  .faq-box {
    width: 100%;
  }
}
.faq-box__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1.8rem 4.5rem;
  transition: 0.15s ease;
}
.faq-box__question p {
  font-size: 1.8rem;
  font-weight: 500;
}
.faq-box__question i {
  font-size: 2rem;
}
.faq-box__answer {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.7;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s ease;
  padding: 0 4.5rem;
}

.active-answer {
  max-height: 20rem;
  padding: 2.8rem 4.5rem;
  transition: 0.4s ease;
}
@media (max-width: 550px) {
  .active-answer {
    max-height: 30rem;
  }
}
@media (max-width: 420px) {
  .active-answer {
    max-height: 55rem;
  }
}

.active-question {
  background-color: #040e4e;
  color: white;
  box-shadow: 0 10px 15px 0 rgba(5, 20, 97, 0.35);
}

.cookies-section {
    
  padding: 10rem 0;
  background-size: 2140px;
  background-repeat: no-repeat;
  background-position: 0 70%;
  background-color: #d61313e8;
  
}


.cookies-content {
  display: flex;
  flex-direction: column;
  color: #fcfcfe;
}
.cookies-content__title {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 80rem;
  line-height: 1.5;
}
.cookies-content__title h5 {
  font-size: 2.2rem;
}
.cookies-content__title h2 {
  font-size: 4.2rem;
  margin-bottom: 1.7rem;
}
.cookies-content__title p {
  font-size: 1.6rem;
  color: #e9e9f1;
}

.all-questions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7rem;
}

.faq-box {
  display: flex;
  flex-direction: column;
  color: #010103;
  background-color: white;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  width: 80rem;
  cursor: pointer;
}
@media (max-width: 850px) {
  .faq-box {
    width: 100%;
  }
}
.faq-box__question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1.8rem 4.5rem;
  transition: 0.15s ease;
}
.faq-box__question p {
  font-size: 1.8rem;
  font-weight: 500;
}
.faq-box__question i {
  font-size: 2rem;
}
.faq-box__answer {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.7;
  max-height: 0;
  overflow: hidden;
  transition: 0.4s ease;
  padding: 0 4.5rem;
}

.active-answer {
  max-height: 20rem;
  padding: 2.8rem 4.5rem;
  transition: 0.4s ease;
}
@media (max-width: 550px) {
  .active-answer {
    max-height: 30rem;
  }
}
@media (max-width: 420px) {
  .active-answer {
    max-height: 55rem;
  }
}

.active-question {
  background-color: #040e4e;
  color: white;
  box-shadow: 0 10px 15px 0 rgba(5, 20, 97, 0.35);
}


.download-section {
  background-image: url("/src/images/banners/bg02.png");
  background-color: #f8f8f8;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 10rem 0;
}
@media (max-width: 700px) {
  .download-section {
    background-image: none;
  }
}

.download-text {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 55rem;
  text-align: left;
}
@media (max-width: 700px) {
  .download-text {
    text-align: center;
    margin: 0 auto;
  }
}
.download-text h2 {
  font-size: 4.2rem;
  color: #010103;
}
.download-text p {
  font-size: 1.6rem;
  color: #706f7b;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
}
.download-text__btns a{
  display: flex;
  gap: 3rem;
  margin-top: 2rem;
  margin-left: 5rem;
  width: 70%;
  
}

@media (max-width: 700px) {
  .download-text__btns {
    justify-content: center;
  }
}
@media (max-width: 550px) {
  .download-text__btns {
    flex-direction: column;
    align-items: center;
  }
}
.download-text__btns img {
  width: 20%;
  cursor: pointer;
}
@media (max-width: 550px) {
  .download-text__btns img {
    width: 22rem;
  }
}

footer {
  background-color: #ffffff;
  padding: 10rem 0;
}

.footer-content {
  display: grid;
  color: #010103;
  grid-template-columns: 27fr 21fr 21fr 21fr;
  gap: 8rem;
  justify-content: center;
  text-align: left;
  color: #010103;
}
@media (max-width: 1100px) {
  .footer-content {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 650px) {
  .footer-content {
    grid-template-columns: 1fr;
  }
}
.footer-content__1 {
  list-style: none;
}
@media (max-width: 650px) {
  .footer-content__1 {
    text-align: center;
  }
}
.footer-content__1 li a {
  text-decoration: none;
  color: #010103;
  transition: all 0.2s;
}
.footer-content__1 li a:hover {
  color: rgb(255, 0, 0);
}
.footer-content__1 li:nth-child(1) {
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
}
.footer-content__1 li:nth-child(1) span {
  font-weight: 700;
}
.footer-content__1 li:nth-child(2) {
  font-size: 1.6rem;
  color: #706f7b;
  line-height: 1.7;
  margin-bottom: 3rem;
}
.footer-content__1 li:nth-child(3), .footer-content__1 li:nth-child(4) {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}
.footer-content__2 {
  list-style: none;
}
@media (max-width: 650px) {
  .footer-content__2 {
    text-align: center;
  }
}
.footer-content__2 a {
  text-decoration: none;
  color: #010103;
  transition: all 0.2s;
}
.footer-content__2 a:hover {
  color: #0c5dc7;
}
.footer-content__2 li {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.footer-content__2 li:nth-child(1) {
  font-size: 2.4rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  cursor: auto;
}

.btn a{
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #0c5dc7;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(55, 48, 255, 0.25);
  transition: all 0.3s;
  border: 2px solid #0c5dc7;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  width: 100%;
}
.btn:hover a{
  box-shadow: 0 10px 15px 0 rgba(55, 48, 255, 0.4);
  background-color: #02060a00;
  color: #03033b;
}

.hero-pages {
  width: 100%;
  height: 41rem;
  background-image: url("/src/images/hero/1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.hero-pages__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(128, 129, 192, 0.463);
}
.hero-pages__text {
  display: flex;
  flex-direction: center;
  z-index: 3;
  position: relative;
  width: 100%;
  height: 35rem;
  justify-content: center;
  color: #171616;
}
.hero-pages__text h3 {
  font-size: 5rem;
  margin-bottom: 0.5rem;
  font-weight: 800;
  margin-top: 15rem ;
}
@media (max-width: 1100px) {
  .hero-pages__text h3 {
    font-size: 4rem;
    text-align: center;
  }
}

.hero-pages__text p {
  font-size: 1.9rem;
  font-weight: 600;
  position: relative;
  display: flex;
  right: 20rem;
  align-items: center;
  margin-top: 12rem ;
  
}

@media (max-width: 1100px) {
  .hero-pages__text p {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 25rem;
    right: 18rem;
    position: relative;
  }
}
.hero-pages__text p a {
  color: #282828;
  text-decoration: none;
  transition: all 0.2s;
}
.hero-pages__text p a:hover {
  color: #ff4d30;
}

.seguros-main {
  margin: 10rem auto;
  display: flex;
  gap: 5rem;
  max-width: 90rem;
  align-items: center;
}
.container-seguros{
  background-color: #f7f8f937;
}

@media (max-width: 960px) {
  .seguros-main {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    max-width: 49rem;
    margin-top: 7rem;
    
  }
}
@media (max-width: 960px) {
  .seguros-main {
    max-width: 70%;
    
  }
}

.seguros-main__img1 {
  width: 25rem;
  
}
@media (max-width: 960px) {
  .seguros-main__img1 {
    margin: 0 auto;
  }
}
@media (max-width: 960px) {
  .seguros-main__img {
    width: 100%;
    height: auto;
  }
}
.seguros-main__text {
  color: #050521;
  display: flex;
  flex-direction: column;
}
.seguros-main__text h3 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}
.seguros-main__text h2 {
  font-size: 4.2rem;
  line-height: 1.2;
}
.seguros-main__text p {
  font-size: 1.6rem;
  color: #212122;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  margin-top: 2rem;
  margin-bottom: 4rem;
  text-align: justify;
}

.seguros-main a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #07115e;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(48, 82, 255, 0.35);
  transition: all 0.3s;
  border: 2px solid #150281;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  bottom: 0rem;
  left: 18rem ;
}
.seguros-main a:hover {
  box-shadow: 0 10px 15px 0 rgba(58, 48, 255, 0.6);
  background-color: #1a1c7c;
}

@media (max-width: 520px) {
  .seguros-main a{
    max-width: 100%;
    left: 3rem;
    align-items: center;
  
  }
}

@media (max-width: 520px) {
  .seguros-main a:hover{
    max-width: 100%;
    left: 3rem;
    align-items: center;
  
  }
}


.seguros-main__text__icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 4rem;
}
@media (max-width: 520px) {
  .seguros-main__text__icons {
    grid-template-columns: 1fr;
    margin: 0 auto;
    gap: 0;
  }
  .seguros-main__text__icons p {
    margin-top: 0;
    text-align: justify;
  }
}
.seguros-main__text__icons__box {
  display: flex;
  flex-direction: column;
  text-align: justify;
}
@media (max-width: 520px) {
  .seguros-main__text__icons__box {
    align-items: center;
    
  }
}
.seguros-main__text__icons__box .last-fk {
  width: 7rem;
}
.seguros-main__text__icons__box img {
  width: 7rem;
}
.seguros-main__text__icons__box span {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 520px) {
  .seguros-main__text__icons__box span {
    text-align: center;
    flex-direction: column;
  }
}
.seguros-main__text__icons__box span h4 {
  font-size: 4.6rem;
}

.container-seguros2{
  background-color: #e3110ab9;
  height: 55rem;
  
  /* background: linear-gradient(#e3110ab9, rgb(55, 92, 241)); */
}

@media (max-width: 960px) {
  .container-seguros2 {
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    max-width: 100%;
    margin-top: 2rem;
    
  }
}

.seguros-main__img{
  width: 40rem;
  height: 50rem;
  float: right;
  margin-top: 2.5%;
  margin-left: 0;
}

@media (max-width: 960px) {
  .seguros-main__img {
    display: flex;
    grid-template-columns: 1fr;
    text-align: center;
    width: 40rem;
    height: 40rem;
    bottom: 10rem;
    right: 15rem;
    position: relative;
    
  }
}

@media (max-width: 530px) {
  .seguros-main__img {
    display: flex;
    grid-template-columns: 1fr;
    text-align: center;
    width: 20rem;
    height: 20rem;
    bottom: 8rem;
    right: 2rem;
    position: relative;
    
  }
}

.seguros-main__text2 {
  color: #f6f6f8;
  display: left;
  flex-direction: column;
}

.seguros-main__text2 h3 {
  color: #04082c;
  display: left;
  flex-direction: column;

}

@media (max-width: 960px) {
  .seguros-main__text2 {
    display: flex;
    grid-template-columns: 1fr;
    text-align: center;
    width: 37rem;
    height: 20rem;
    bottom: 50rem;
    right: -20rem;
    position: relative;
    
  }
}

@media (max-width: 530px) {
  .seguros-main__text2 {
    display: flex;
    grid-template-columns: 1fr;
    text-align: center;
    width: 30rem;
    height: 20rem;
    bottom: 14rem;
    right: 2rem;
    position: relative;
    
  }
}


.title-2{
  color: #04082c;
  display: left;
  flex-direction: column;
  font-size: 4.5rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
  
}

@media (max-width: 960px) {
  .title-2 {
    font-size: 4rem;
    
  }
}

@media (max-width: 560px) {
  .title-2 {
    font-size: 3rem;
    
  }
}

.title-3{
  color: #071a79;
  display: left;
  flex-direction: column;
  font-size: 4.5rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
  
}

@media (max-width: 960px) {
  .title-3 {
    font-size: 3.5rem;
    
  }
}

@media (max-width: 560px) {
  .title-3 {
    font-size: 3.5rem;
    
  }
}


.title-4{
  color: #071a79;
  display: left;
  flex-direction: column;
  font-size: 4.5rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
  
}

@media (max-width: 960px) {
  .title-4 {
    font-size: 3.5rem;
    
  }
}

@media (max-width: 560px) {
  .title-4 {
    font-size: 3.5rem;
    
  }
}


.container-seguros2 a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #08136c;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(48, 82, 255, 0.35);
  transition: all 0.3s;
  border: 2px solid #150281;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  bottom: 0rem;
  left: 18rem ;
}
.container-seguros2 a:hover {
  box-shadow: 0 10px 15px 0 rgba(58, 48, 255, 0.6);
  background-color: #0e1094;
}

@media (max-width: 960px) {
  .container-seguros2 a{
    max-width: 70%;
    max-height: 30%;
    left: 8rem;
    align-items: center;
    bottom: 3rem;
    font-size: 1.2rem;

  
  }
}

@media (max-width: 530px) {
  .container-seguros2 a{
    max-width: 50%;
    max-height: 30%;
    left: 8rem;
    align-items: center;
    bottom: 5rem;
    font-size: 1.2rem;

  
  }
}

.seguros-main__text2 h3 {
  font-size: 2.2rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}

@media (max-width: 960px) {
  .seguros-main__text2 h3 {
    font-size: 1.8rem;
    width: 28rem;
    margin-left: 2rem;
    
  }
}

.seguros-main__text2 h2 {
  font-size: 4.2rem;
  line-height: 1.2;
}

@media (max-width: 960px) {
  .seguros-main__text2 h2 {
    font-size: 4rem;
    
  }
}

@media (max-width: 530px) {
  .seguros-main__text2 h2 {
    font-size: 3rem;
    
  }
}
.seguros-main__text2 p {
  font-size: 1.6rem;
  color: #e9e8f0;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  margin-top: 2rem;
  margin-bottom: 4rem;
  text-align: justify;
}

@media (max-width: 960px) {
  .seguros-main__text2 p {
    font-size: 1.7rem;
    width: 30rem;
    margin-left: 3rem;
    
  }
}

@media (max-width: 530px) {
  .seguros-main__text2 p {
    font-size: 1.2rem;
    width: 25rem;
    margin-left: 3rem;
    
  }
}

.seguros-main__text__icons2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 4rem;
}
@media (max-width: 960px) {
  .seguros-main__text__icons2 {
    grid-template-columns: 1fr;
    margin: 0 auto;
    gap: 0;
  }
  .seguros-main__text__icons2 p {
    margin-top: 50rem;
  }
}
.seguros-main__text__icons__box2 {
  display: flex;
  flex-direction: column;
}
@media (max-width: 520px) {
  .seguros-main__text__icons__box2 {
    align-items: center;
    
  }
}
.seguros-main__text__icons__box2 .last-fk {
  width: 7rem;
}
.seguros-main__text__icons__box2 img {
  width: 7rem;
}
.seguros-main__text__icons__box2 span {
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 520px) {
  .seguros-main__text__icons__box2 span {
    text-align: center;
    flex-direction: column;
  }
}
.seguros-main__text__icons__box2 span h4 {
  font-size: 4.6rem;
}

/* Seguros Main 3 */

.choose-section-3 {
  background-color: rgb(255, 255, 255);
  padding: 2rem 0 0rem 0;
  /* background-image: url("/src/images/chooseUs/iconos7.png");  */
  background-position: -60px 90px;
  background-size: 530px;
  background-repeat: no-repeat;
  height: 80rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 0rem;
  
  
}


@media (max-width: 960px) {
  .choose-section-3  {
    
    width: 10%;
    left: 15rem ;
  }
}

@media (max-width: 550px) {
  .choose-section-3  {
    
    height: 110%;
    left: 4rem ;

    
  }
}

.choose-container-3 {
  display: flex;
  flex-direction: column;
}


.choose-container-3__img {
  width: 90%;
  height: auto;
  margin: 0 auto;
}

.card-body h3{
  color: #071a79;
  font-weight: 800;
}

.choose-container-3 a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #07115e;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(48, 82, 255, 0.35);
  transition: all 0.3s;
  border: 2px solid #150281;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  Top: 3rem;
  right: 1rem ;
}
.choose-container-3 a:hover {
  box-shadow: 0 10px 15px 0 rgba(58, 48, 255, 0.6);
  background-color: #1a1c7c;
}

@media (max-width: 960px) {
  .choose-container-3 a {
    font-size: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;

  }
}

@media (max-width: 550px) {
  .choose-container-3 a {
    font-size: 1rem;
    width: 100rem;
    
    
  }
}


@media (max-width: 960px) {
  .choose-container-3__img {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .choose-container-3__img {
    width: 100%;
  }
}

.text-container-seguros-3 {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  width: 100%;
}
@media (max-width: 960px) {
  .text-container-seguros-3 {
    flex-direction: column;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 5.5rem;
    width: 50rem;
  }
}

@media (max-width: 530px) {
  .text-container-seguros-3 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    gap: 5.5rem;
    width: 40rem;
  }
}

.text-container__left-seguros-3 {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #010103;
}
@media (max-width: 1000px) {
  .text-container-3__left {
    align-items: center;
    text-align: center;
  }
}
.text-container-3__left h4 {
  font-size: 2.2rem;
  margin-bottom: 0.7rem;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
  text-align: left;
}
.text-container-3__left-seguros h2 {
  font-size: 4.2rem;
  line-height: 1.2;
  margin-bottom: 2rem;
  text-align: center;
  width: 45rem;
}

@media (max-width: 960px) {
  .text-container-3__left-seguros h2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
    font-size: 3rem;
    position: relative;
    left: -5rem;
    max-width: 40rem;
  }
}


@media (max-width: 530px) {
  .text-container-3__left-seguros h2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
    width: 30rem;
  }
}


.text-container-3__left-seguros p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  color: #1e1e20;
  margin-bottom: 3.3rem;
  text-align: justify;
  width: 100%;
}

@media (max-width: 960px) {
  .text-container-3__left-seguros p {
    flex-direction: column;
    align-items: center;
    text-align: justify;
    font-size: 1.2rem;
    gap: 5.5rem;
    width: 100%;
  }
}

@media (max-width: 530px) {
  .text-container-3__left-seguros p {
    flex-direction: column;
    align-items: center;
    text-align: justify;
    gap: 5.5rem;
    width: 20rem;
  }
}


.text-container-3__right-seguros {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #010103;
  

}

.text-container-3__right-seguros h2 {
  font-size: 4.2rem;
  line-height: 1.2;
  margin-bottom: 2rem;
  text-align: center;
}

@media (max-width: 960px) {
  .text-container-3__right-seguros h2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    left: -4rem;
    gap: 5.5rem;
    font-size: 3.5rem;
    width: 30rem;
  }
}

@media (max-width: 530px) {
  .text-container-3__right-seguros h2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
    width: 25rem;
  }
}

.text-container-3__right-seguros p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  color: #19191b;
  margin-bottom: 3.3rem;
  text-align: justify;
  width: 100%;
  
}


@media (max-width: 960px) {
  .text-container-3__right-seguros p {
    flex-direction: column;
    align-items: center;
    text-align: justify;
    font-size: 1.2rem;
    gap: 5.5rem;
    width: 100%;
  }
}

@media (max-width: 530px) {
  .text-container-3__right-seguros p {
    flex-direction: column;
    align-items: center;
    text-align: justify;
    gap: 5.5rem;
    width: 20rem;
  }
}


.text-container-3__right__box {
  display: flex;
}
@media (max-width: 550px) {
  .text-container-3__right__box {
    flex-direction: column;
    align-items: center;
  }
}
.text-container-3__right__box img {
  width: 40rem;
  
  margin-right: 80rem;
}
.text-container-3__right__box__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}


.text-container-3__right__box__text h4 {
  font-size: 2.4rem;
}
.text-container-3__right__box__text p {
  font-size: 1.6rem;
  color: #0a0a0a;
  font-family: "Rubik", sans-serif;
  line-height: 1.3;
  
}

/* Gastos Finales */

.choose-section-6 {
  background-color: #052E5E;
  padding: 2rem 0 0rem 0;
  background-image: url("/src/images/Seguros/iconos.png"); 
  background-position: -60px 0px;
  background-size: 650px;
  background-repeat: no-repeat;
  
  position: relative;
  margin-bottom: 20rem;
  height: 50rem;
  
  
}

@media (max-width: 960px) {
  .choose-container-6 {
    width: 100%;
    left: 10rem;
    position: relative;
  }
}



.choose-container-6 {
  display: flex;
  flex-direction: column;
}
.choose-container-6__img {
  width: 90%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 960px) {
  .choose-container-6__img {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .choose-container-6__img {
    width: 100%;
  }
}

.text-container-seguros-6 {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  width: 100%;
}


@media (max-width: 930px) {
      .text-container-seguros-6 {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 5.5rem;
        width: 40rem;
        position: relative;
        right: -5rem;
        bottom: 3rem;
    
  }
}

@media (max-width: 530px) {
  .text-container-seguros-6 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
    width: 40rem;
    position: relative;
    right: 37rem;
    bottom: 3rem;
  }
}


.text-container__left-seguros-6 {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #ffffff;
}
@media (max-width: 960px) {
  .text-container-6__left {
    align-items: center;
    text-align: center;
    
  }
}

@media (max-width: 530px) {
  .text-container-6__left {
    align-items: center;
    text-align: center;
    color: #020228;
    
  }
}

.text-container-6__left-seguros h3 {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 2rem;
  text-align: center;
  width: 45rem;
  color: #ffffff;
  position: relative;
  left: 15rem;
  
}

@media (max-width: 960px) {
  .text-container-6__left-seguros h3 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
    width: 30rem;
    font-size: 2.5rem;
    position: relative;
    left: 50rem;
    
  }
}

@media (max-width: 530px) {
  .text-container-6__left-seguros h3 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
    color: #020228;
    width: 30rem;
    font-size: 2.5rem;
    
  }
}


.text-container-6__left-seguros h2 {
  font-size: 6rem;
  line-height: 1.2;
  margin-bottom: 2rem;
  width: 45rem;
  color: #ffffff;
  left: 50rem;
  position: relative;
}


@media (max-width: 960px) {
  .text-container-6__left-seguros h2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
    width: 30rem;
    font-size: 4rem;
  }
}

@media (max-width: 530px) {
  .text-container-6__left-seguros h2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #020228;
    gap: 5.5rem;
    width: 30rem;
    font-size: 4rem;
  }
}

.text-container-6__left-seguros p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  color: #f7f7fd;
  margin-bottom: 3.3rem;
  text-align: justify;
  width: 45rem;
  margin-left: 50rem;

}

@media (max-width: 960px) {
  .text-container-6__left-seguros p {
    flex-direction: column;
    align-items: center;
    text-align: justify;
    gap: 5.5rem;
    width: 30rem;
    font-size: 1.5rem;
  }
}

@media (max-width: 530px) {
  .text-container-6__left-seguros p {
    flex-direction: column;
    background-color: #0202288f;
    align-items: center;
    text-align: justify;
    gap: 5.5rem;
    width: 30rem;
    color: #ebebf3;
    font-size: 1.3rem;
  }
}

.text-container-6__left-seguros a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #010105;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(105, 105, 106, 0.35);
  transition: all 0.3s;
  border: 2px solid #fafafa;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  top: 3rem;
  left: 50rem ;
}
.text-container-6__left-seguros a:hover {
  box-shadow: 0 10px 15px 0 rgba(83, 83, 83, 0.6);
  background-color: #ffffff;
  color: #010103;
}


@media (max-width: 960px) {
  .text-container-6__left-seguros a {
    width: fit-content;
    left: 12rem;
    top: 5rem;
  }
}

@media (max-width: 530px) {
  .text-container-6__left-seguros a {
   left: 25rem;
  }
}

.text-container-6__right-seguros {
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #ffffff;
  

}

.title-6{
  color: #2CBFAE;
  display: flex;
  font-size: 6rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  margin-bottom: -8rem;
  bottom: 2rem;
  right: 22rem;
  position: relative;
  
}

.text-container-6__right-seguros h2 {
  font-size: 4.2rem;
  line-height: 1.2;
  margin-bottom: 2rem;
  text-align: center;
}

@media (max-width: 1000px) {
  .text-container-6__right-seguros h2 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
    width: 30rem;
  }
}

.text-container-6__right-seguros p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 3.3rem;
  text-align: justify;
  width: 45rem;
  
}


@media (max-width: 1000px) {
  .text-container-6__right-seguros p {
    flex-direction: column;
    align-items: center;
    text-align: justify;
    gap: 5.5rem;
    width: 30rem;
  }
}

.text-container-6__right__box {
  display: flex;
}
@media (max-width: 550px) {
  .text-container-6__right__box {
    flex-direction: column;
    align-items: center;
  }
}
.text-container-6__right__box img {
  width: 40rem;
  
  margin-right: 80rem;
}
.text-container-6__right__box__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}


.text-container-6__right__box__text h4 {
  font-size: 2.4rem;
}
.text-container-6__right__box__text p {
  font-size: 1.6rem;
  color: #ffffff;
  font-family: "Rubik", sans-serif;
  line-height: 1.3;
  
}

/* Seccion Seguros Vision */

.choose-section-4 {
  background-color: rgba(190, 237, 226, 0.217);
  padding: 2rem 0 0rem 0;
  /* background-image: url("/src/images/chooseUs/bg.png"); */
  background-position: -60px 300px;
  background-size: auto;
  background-repeat: no-repeat;
  margin-top: 1rem;
  border: 50%;
}

.choose-container-4 {
  display: flex;
  flex-direction: column;
}
@media (max-width: 960px) {
  .choose-container-4 {
    width: 60%;
  }
}
@media (max-width: 560px) {
  .choose-container-4 {
    width: 60%;
    margin-top: 5rem;
  }
}
.choose-container-4__img {
  width: 100%;
  height: auto;
  margin: 0 auto;
}


@media (max-width: 960px) {
  .choose-container-4__img {
    width: 60%;
  }
}

@media (max-width: 550px) {
  .choose-container-4__img {
    width: 100%;
    position: relative;
    bottom: 30rem;
  }
}

.img-vision{
  width: 90%;
  height: auto;
  margin: 0 auto;
  position: relative;
  bottom: 1rem;
}

@media (max-width: 550px) {
  .img-vision {
    width: 90%;
    position: relative;
    margin-bottom: 20rem;
  }
}

.text-container-4 {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  width: 100%;
  
}
@media (max-width: 1000px) {
  .text-container-4 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
  }
}
.text-container-4__left {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #010103;
}
@media (max-width: 1000px) {
  .text-container-4__left {
    align-items: center;
    text-align: center;
    width: 30rem;
  }
}

.text-container-4__left h2 {
  font-size: 4.2rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

@media (max-width: 550px) {
  .text-container-4__left h2{
    position: relative;
    margin-top: 0rem; 
    left: 6rem;
  }
}

.text-container-4__left p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  color: #706f7b;
  margin-bottom: 3.3rem;
  text-align: justify;
}

@media (max-width: 550px) {
  .text-container-4__left p{
    position: relative;
    margin-top: 35rem; 
    left: 6rem;
  }
}

.text-container-4__left a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #07115e;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(48, 82, 255, 0.35);
  transition: all 0.3s;
  border: 2px solid #150281;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
}
.text-container-4__left a:hover {
  box-shadow: 0 10px 15px 0 rgba(58, 48, 255, 0.6);
  background-color: #1a1c7c;
}

@media (max-width: 550px) {
  .text-container-4__left a {
    margin-right: -10rem;
  }
}

.text-container-4__right {
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  max-width: 44rem;
}
.text-container-4__right__box {
  display: flex;
}
@media (max-width: 550px) {
  .text-container-4__right__box {
    flex-direction: column;
    align-items: center;
  }
}
.text-container-4__right__box img {
  width: 100%;
  bottom: 0rem;
  right: 4rem;
}

@media (max-width: 960px) {
  .text-container-4__right__box img{
    bottom: 45rem;
    width: 60%;
    right: -35rem;
  }
}


@media (max-width: 550px) {
  .text-container-4__right__box img{
    bottom: 70rem;
    right: -2rem;
    width: 140%;
  }
}

.text-container-4__right__box__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}


.text-container-4__right__box__text h4 {
  font-size: 2.4rem;
}
.text-container-4__right__box__text p {
  font-size: 1.6rem;
  color: #0a0a0a;
  font-family: "Rubik", sans-serif;
  line-height: 1.3;
}

/* Seguros Dentales */


.choose-container-5 {
  display: flex;
  flex-direction: column;
  margin-top: 7rem;
}

@media (max-width: 960px) {
  .choose-container-5 {
    height: 35rem;
  }
}

@media (max-width: 550px) {
  .choose-container-5 {
    margin-top: -56rem;
    height: 90rem;
  }
}


.img-dent{
  width: 90%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 550px) {
  .img-dent {
    width: 50%;
  }
}

.text-container-5 {
  display: flex;
  justify-content: space-around;
  margin-top: 3rem;
  width: 100%;
  
}
@media (max-width: 1000px) {
  .text-container-5 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5.5rem;
  }
}
.text-container-5__left {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  color: #010103;
  margin-right: 20rem;
  margin-top: 7rem;
}
@media (max-width: 1000px) {
  .text-container-5__left {
    align-items: center;
    text-align: center;
    width: 30rem;
  }
}
.text-container-5__left a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #07115e;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(48, 82, 255, 0.35);
  transition: all 0.3s;
  border: 2px solid #150281;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  bottom: 20rem;
  left: 18rem ;
}
.text-container-5__left a:hover {
  box-shadow: 0 10px 15px 0 rgba(58, 48, 255, 0.6);
  background-color: #1a1c7c;
}

@media (max-width: 1000px) {
  .text-container-5__left a{
    
    bottom: 75rem;
    width: 30rem;
  }
}

@media (max-width: 560px) {
  .text-container-5__left a{
    
    bottom: 45rem;
    width: 30rem;
    left: 10rem;
  }
}


.text-container-5__left h2 {
  font-size: 6rem;
  line-height: 1.2;
  margin-bottom: 20rem;
  position: relative;
  right: 12rem;
  display: flex;
}

@media (max-width: 1000px) {
  .text-container-5__left h2 {
    align-items: center;
    text-align: center;
    width: 30rem;
    bottom: 65rem;
    left: 37rem;
    font-size: 5rem;
  }
}

@media (max-width: 560px) {
  .text-container-5__left h2 {
    align-items: center;
    text-align: center;
    font-size: 4;
    width: 30rem;
    bottom: 60rem;
    left: 19rem;
    font-size: 5rem;
  }
}

.text-container-5__left p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  line-height: 1.5;
  color: #706f7b;
  bottom: 26rem;
  text-align: justify;
  margin-left: 18rem;
  position: relative;
  
}

@media (max-width: 960px) {
  .text-container-5__left p {
    align-items: center;
    text-align: justify;
    position: relative;
    bottom: 78rem;
    font-size: 1.6;
    width: 26rem;
    left: 25rem ;
  }
}

@media (max-width: 560px) {
  .text-container-5__left p {
    align-items: center;
    text-align: justify;
    bottom: 48rem;
    font-size: 1.6;
    width: 30rem;
    left: 1rem ;
  }
}

.text-container-5__left a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #07115e;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(48, 82, 255, 0.35);
  transition: all 0.3s;
  border: 2px solid #150281;
  font-size: 1.6rem;
  width: -moz-fit-content;
  width: fit-content;
}
.text-container-4__left a:hover {
  box-shadow: 0 10px 15px 0 rgba(58, 48, 255, 0.6);
  background-color: #1a1c7c;
}
.text-container-5__right {
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  max-width: 44rem;
}
.text-container-5__right__box {
  display: flex;
}
@media (max-width: 550px) {
  .text-container-5__right__box {
    flex-direction: column;
    align-items: center;
  }
}
.text-container-5__right__box img {
  width: 50rem;
  right: 30%;
  position: relative;
  bottom: 9rem;
}

@media (max-width: 960px) {
  .text-container-5__right__box img {
   width: 40rem;
   margin-top: -30rem;
  }
}


@media (max-width: 550px) {
  .text-container-5__right__box img {
   width: 30rem;
   margin-top: 15rem;
  }
}

.Notary-main{
  background-image: url("/src/images/NotaryPublic/iconos.svg"); 
  background-position: -140px 0px;
  background-size: 2800px;
  padding: 5rem 0;
  margin-block-end: 5rem;
  
}

.container-card{
  padding: 1rem;
  width: 90%;
  margin-top: 3rem;
  margin-left: 3rem;
  height: 70rem;
  
}

@media (max-width: 960px){
  .container-card{
    width: 100%;
height: 100%;
  }
}

@media (max-width: 530px){
  .container-card{
    width: 100%;
    height: 100%;
    margin-left: -2rem;
  }
}

.Notary__title{
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  max-width: 70rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 12rem;

}

@media(max-width: 960px){
.Notary__title{
  margin-top: 20rem;
}
}

.btn-notary-1 a {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background-color: #ff0000;
  padding: 1.5rem 4rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(255, 48, 48, 0.35);
  transition: all 0.3s;
  border: 2px solid #ff0000;
  font-size: 1.6rem;
  width: -moz-fit-content;
  position: relative;
  bottom: 12rem;
  left: 10rem;
  width: fit-content;
}
.btn-notary-1 a:hover {
  color: #010103;
  box-shadow: 0 10px 15px 0 rgba(255, 48, 48, 0.6);
  background-color: #ffa4a47c;
}

.btn-notary-5 a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-weight: 700;
  background-color: #160554;
  padding: 1.5rem 2.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(6, 6, 87, 0.35);
  transition: all 0.3s;
  border: 2px solid #0a0a0a;
  font-size: 1.6rem;
  width: -moz-fit-content;
  position: relative;
  bottom: 14.5rem;
  left: -15rem;
  width: fit-content;
}
.btn-notary-5 a:hover {
  color: #010103;
  box-shadow: 0 10px 15px 0 rgba(40, 22, 147, 0.6);
  background-color: #fefcfcb3;
}

@media (max-width: 960px) {
  .btn-notary-1 a{
    top: -15rem;
  }
}
@media (max-width: 530px) {
  .btn-notary-1 a{
    top: -10rem;
    left: -5rem;
  }
}

@media (max-width: 960px) {
  .btn-notary-5 a{
    bottom: 17.5rem;
    margin-left: -5rem;
  }
}
@media (max-width: 530px) {
  .btn-notary-5 a{
    bottom: 3rem;
    margin-right: -25rem;
  }
}

.Notary__title img{
  position: relative;
  left: 70rem;
  bottom: 15rem;
  width: 30%;
}

@media (max-width:960px){
  .Notary__title img{
    position: relative;
    left: 25rem;
    bottom: 35rem;
    width: 30%;
  }  
}

@media (max-width:530px){
  .Notary__title img{
    position: relative;
    left: 1rem;
    bottom: 50rem;
    width: 60%;
  }  
}


.Notary__title h2{
font-family:  "Rubik", sans-serif;
font-size: 7rem;
font-weight: 500;
}

@media (max-width: 530px){
  .Notary__title h2{
    font-size: 6.5rem;
    margin-left: -10rem;
    
  }
}

.Notary__title p{
  font-size: 1.5rem;
  width: 90%;
}

@media (max-width: 530px){
  .Notary__title p{
    margin-left: -4rem;
  }
}

.row{
  background: none;
}

 .card{

  box-shadow: 5px 5px 50px 10px rgba(0, 0, 0, 0.08);
  margin-left: 5rem;
  height: 100%
  
} 

@media (max-width: 960px){
  .card{
    width: 100%;
height: 100%;
  }
}

@media (max-width: 530px){
  .card{
    width: 100%;
height: 100%;
  }
}

.book-banner {
  display: flex;
  width: 100%;
  height: 20rem;
  background-image: url("/src/images/banners/fondo.png");
  position: relative;
  margin-top: 7rem;
}
@media (max-width: 750px) {
  .book-banner {
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem 0;
  }
}
.book-banner__overlay {
  background-color: #0b0842;
  opacity: 0.89;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.text-content {
  color: white;
  z-index: 5;
  position: relative;
  width: 100%;
  height: 20rem;
  display: flex;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 750px) {
  .text-content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
  }
}
.text-content h2 {
  font-size: 3.2rem;
}
.text-content span {
  display: flex;
  font-size: 2.7rem;
  gap: 1rem;
  align-items: center;
  color: #ff4d30;
  white-space: nowrap;
}


.team-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 4rem;
  align-items: center;
  text-align: center;
  padding: 10rem 2rem;
  width: 110rem;
  margin: 0 auto;
}
@media (max-width: 1150px) {
  .team-container {
    grid-template-columns: 1fr 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}
@media (max-width: 800px) {
  .team-container {
    grid-template-columns: 1fr;
    width: -moz-fit-content;
    width: fit-content;
  }
}
.team-container__box {
  width: 33rem;
  background-color: white;
  box-shadow: 0px 20px 10px 0px rgba(0, 0, 0, 0.08);
}
@media (max-width: 400px) {
  .team-container__box {
    width: 100%;
  }
}
.team-container__box__img-div {
  width: 100%;
  height: auto;
  background-color: #f6f6f6;
}
.team-container__box__img-div img {
  width: 100%;
}
.team-container__box__descr {
  color: #010103;
  padding: 3rem;
}
.team-container__box__descr h3 {
  font-size: 2.2rem;
}
.team-container__box__descr p {
  font-size: 1.6rem;
  font-weight: 500;
  color: #777777;
}

.contact-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin: 0 auto;
  color: #030201;
  padding: 10rem 2rem;
  background-image: url("/src/images/banners/bg-contact.png");
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 950px) {
  .contact-div {
    grid-template-columns: 1fr;
    text-align: center;
  }
}
.contact-div__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 41rem;
}
@media (max-width: 950px) {
  .contact-div__text {
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}
.contact-div__text h2 {
  font-size: 4.2rem;
  line-height: 1.3;
  margin-bottom: 2rem;
}
.contact-div__text p {
  font-size: 1.6rem;
  font-family: "Rubik", sans-serif;
  color: #706f7b;
  line-height: 1.6;
  margin-bottom: 2rem;
}
.contact-div__text a {
  text-decoration: none;
  color: #010103;
  font-size: 1.6rem;
  font-weight: 500;
  transition: all 0.2s;
  margin-bottom: 0.5rem;
}
.contact-div__text a:hover {
  color: #ff4d30;
}
.contact-div__form {
  display: flex;
  flex-direction: column;
}
.contact-div__form form {
  display: flex;
  flex-direction: column;
}
.contact-div__form form label {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.contact-div__form form label b {
  color: #1760fe;
}
.contact-div__form form input {
  background-color: #f2f2f2;
  padding: 19px 30px 19px 30px;
  font-size: 1.6rem;
  border: none;
  outline: none;
  margin-bottom: 2.3rem;
}
.contact-div__form form textarea {
  background-color: #f2f2f2;
  height: 18rem;
  padding: 19px 30px 19px 30px;
  font-size: 1.6rem;
  border: none;
  outline: none;
  margin-bottom: 2.5rem;
}
.contact-div__form form button {
  background-color: #0a45c4;
  padding: 1.8rem 3rem;
  border-radius: 0.3rem;
  box-shadow: 0 10px 15px 0 rgba(48, 55, 255, 0.35);
  transition: all 0.3s;
  border: 2px solid #1a18a7;
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
}
.contact-div__form form button:hover {
  box-shadow: 0 10px 15px 0 rgba(48, 86, 255, 0.6);
  background-color: #1760fe;
}/*# sourceMappingURL=styles.css.map */

